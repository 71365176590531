<template>
  <div v-if="creatingCompany" class="w-100 text-center p-3">
    <h2>
      Adding <span id="company-name">{{ newFullCompanyName }}</span> to your account
    </h2>
    <ct-centered-spinner />
  </div>

  <div v-else class="w-100">
    <div v-if="loaded" class="confirm-company">
      <h3 class="header-text">
        Great! Let's create the company record in your account.
      </h3>
      <div class="company-container">
        <div class="company-data">
          <ul>
            <li>
              Company Name:
            </li>
            <li>
              {{ newFullCompanyName }}
            </li>
            <li>
              Jurisdiction:
            </li>
            <li>
              {{ jurisdiction.state_province_region }}
            </li>
            <li>
              Entity Type:
            </li>
            <li>
              {{ entityType.name }}
            </li>
          </ul>
        </div>
        <div class="svg-container">
          <font-awesome-svg :type="'checklist'" :fill="'black'" />
        </div>
      </div>
      <hr>
      <div class="button-nav-container">
        <previous-button-component />
        <button
          class="continue-btn btn btn-primary"
          aria-label="continue button"
          @click="createNewCompany"
        >
          Confirm and add company
        </button>
      </div>
    </div>
    <div v-else class="center-spinner">
      <ct-centered-spinner />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'ConfirmCompany',
  components: {
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    FontAwesomeSvg:           () => import('@/components/shared/FontAwesomeSvg'),
    PreviousButtonComponent:  () => import('./PreviousButtonComponent'),
  },
  mixins: [makeToastMixin],
  data() {
    return {
      creatingCompany: false,
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'entityTypeSuffix',
      'companyName',
      'loaded',
    ]),
    ...mapGetters('stageline', [
      'accountCompanies',
    ]),

    newFullCompanyName() {
      return this.entityTypeSuffix ? this.companyName + ' ' + this.entityTypeSuffix : this.companyName
    },
  },
  async mounted() {
    this.showLoading()
  },
  methods: {
    ...mapActions('stageline', [
      'getAccountCompaniesSimple',
    ]),
    ...mapActions('stagelineCreateCompany', [
      'createCompany',
      'setCreatingCompany',
      'setCurrentSlide',
      'setLoaded',
    ]),
    async createNewCompany() {
      this.creatingCompany = true
      const result = await this.createCompany()

      if (result.success) {
        // todo check if we want full rather than simple, might be ok
        await this.getAccountCompaniesSimple()
        this.setCreatingCompany(true)
        this.setCurrentSlide('CompanyCreated')
        this.zeroCompanyAccountConverted()
      } else {
        this.errorToast('Error', 'An error has occurred adding the company to your account')
      }
      this.creatingCompany = false
    },
    showLoading() {
      this.setLoaded(false)
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
    zeroCompanyAccountConverted() {
      if (this.accountCompanies.length === 1) {
        createOrFindClientInteractionLog({
          category: 'page-interaction',
          subCategory: 'stageline-v2-landing-page',
          interaction: {
            action: 'completed',
            name: 'stageline-zero-companies',
            location: 'stageline',
          },
          incompleteLogFromToday: true,
          completeLog: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

div.confirm-company {
  width: 100%;

  .header-text {
    display: inline-block;
    text-align: left;
  }
  div.company-container {
    padding-top: 1.0em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;

    div.company-data {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(200px, 2fr);
        justify-items: start;
        align-items: center;
        column-gap: 1.2em;

        li {
          padding: 0.6em 0;
        }

        li:nth-child(odd) {
          font-weight: 700;
        }
      }
    }
  }

  div.button-nav-container {
    width: 100%;
    margin-top: 3.125em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;

    button.continue-btn {
      justify-self: flex-end;
      background: $ct-ui-primary;
      border-color: $ct-ui-primary;

      &:hover {
        background: $ct-ui-primary-dark;
        border-color: $ct-ui-primary;
      }
    }
  }
}

#company-name {
  color: #000864;
}

@media only screen and (max-width: 660px) {
  div.confirm-company {

    div.company-container {

      div.svg-container {
        display: none;
      }
    }
    div.button-nav-container {
      row-gap: 0.8em;
      flex-direction: column;

      button.continue-btn {
        justify-self: unset;
      }
    }
  }
}
.previous-button {
  color: #2FB2AE;
}
</style>
