var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.creatingCompany
    ? _c(
        "div",
        { staticClass: "w-100 text-center p-3" },
        [
          _c("h2", [
            _vm._v("\n    Adding "),
            _c("span", { attrs: { id: "company-name" } }, [
              _vm._v(_vm._s(_vm.newFullCompanyName)),
            ]),
            _vm._v(" to your account\n  "),
          ]),
          _c("ct-centered-spinner"),
        ],
        1
      )
    : _c("div", { staticClass: "w-100" }, [
        _vm.loaded
          ? _c("div", { staticClass: "confirm-company" }, [
              _c("h3", { staticClass: "header-text" }, [
                _vm._v(
                  "\n      Great! Let's create the company record in your account.\n    "
                ),
              ]),
              _c("div", { staticClass: "company-container" }, [
                _c("div", { staticClass: "company-data" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v("\n            Company Name:\n          "),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.newFullCompanyName) +
                          "\n          "
                      ),
                    ]),
                    _c("li", [
                      _vm._v("\n            Jurisdiction:\n          "),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.jurisdiction.state_province_region) +
                          "\n          "
                      ),
                    ]),
                    _c("li", [
                      _vm._v("\n            Entity Type:\n          "),
                    ]),
                    _c("li", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.entityType.name) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "svg-container" },
                  [
                    _c("font-awesome-svg", {
                      attrs: { type: "checklist", fill: "black" },
                    }),
                  ],
                  1
                ),
              ]),
              _c("hr"),
              _c(
                "div",
                { staticClass: "button-nav-container" },
                [
                  _c("previous-button-component"),
                  _c(
                    "button",
                    {
                      staticClass: "continue-btn btn btn-primary",
                      attrs: { "aria-label": "continue button" },
                      on: { click: _vm.createNewCompany },
                    },
                    [_vm._v("\n        Confirm and add company\n      ")]
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "center-spinner" },
              [_c("ct-centered-spinner")],
              1
            ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }